/* eslint-disable max-len */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { BreakpointTrackerContext } from '@jutro/layout';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { MetadataContent } from '@jutro/uiconfig';
import htmlParser from 'html-react-parser';
import { withViewModelService, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import PropTypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import CoverageUtil from '../../util/CoverageUtil';
import { LoadSaveService, Submission } from 'gw-capability-quoteandbind';

import YarisCarImage from './images/branding/tya/toyota_yaris.png';
import MSMLogo from './images/aggregators/msm-logo.png';
import CTMLogo from './images/aggregators/ctm-logo.png';
import GCLogo from './images/aggregators/gc-logo.png';
import USLogo from './images/aggregators/us-logo.png';
import CONFUSEDLogo from './images/aggregators/confused-logo.png';
import QZLogo from './images/aggregators/quotezone.png';

import { ErrorBoundary } from 'gw-portals-error-react';

import metadata from './AggRetrievalPage.metadata.json5';
import messages from './AggRetrievalPage.messages.js';
import styles from './AggRetrievalPage.module.scss';
import { brandingData } from 'gw-portals-branding-js';
import { TranslatorContext, useTranslator } from '@jutro/locale';
import messagesTranslationsSet from './AggRetrievalPage.messages';

function AggRetrievalPage(props) {
    const translator = useTranslator();
    const brand = brandingData.BRANDING;
    const { wizardData: submissionVM, updateWizardData, history } = props;
    const { CustomQuoteService } = useDependencies('CustomQuoteService');
    const viewModelService = useContext(ViewModelServiceContext);
    const [AggRetrievalDTO, setAggRetrievalDTO] = useState({});
    const [isAggsLoading, updateIsAggsLoading] = useState(false);
    const [showAggsMessage, setShowAggsMessage] = useState(false);
    const [aggLogoPath, setAggLogoPath] = useState(MSMLogo);
    const [AggCoverages, setAggCoverages] = useState([]);
    const [Params, setParams] = useState([]);
    const AGG_CODE = {
      MSM: 'MSM',
      CTM: 'CTM',
      GC: 'GC',
      US: 'US',
      CONFUSED: 'CONFUSED',
      QZ: 'QZ'
    };

    const AGG_LOGO_PATH = {
      MSM: MSMLogo,
      CTM: CTMLogo,
      GC: GCLogo,
      US: USLogo,
      CONFUSED: CONFUSEDLogo,
      QZ: QZLogo
    };

    useEffect(() => {
      
        console.log(`Agg Deeplink data:
            calcRefId: ${window.aggQuoteID_AND}
            postcode: ${window.aggPostCode_AND}
            Breakdown: ${window.aggBreakdown_AND}
            Legal: ${window.aggLegal_AND};
            CourtesyCar: ${window.courtesyCar_AND}`);
        
        const quoteID = window.aggQuoteID_AND;
        const postCode = window.aggPostCode_AND;
        const Breakdown = window.aggBreakdown_AND;
        const Legal = window.aggLegal_AND;
        const CourtesyCar = window.courtesyCar_AND;
        window.aggQuoteID_AND = undefined;
        window.aggPostCode_AND = undefined;
        window.aggBreakdown_AND = undefined;
        window.aggLegal_AND = undefined;
        window.courtesyCar_AND = undefined;
        const search = history.location.search;
        const params = new URLSearchParams(search);
        setParams(params);
        if (!quoteID || !postCode || !params.get('agg') || !AGG_CODE[params.get('agg').toUpperCase()]) {
          history.push({ 
            pathname: '/error',
            data: {appErrorCode: 101, baseError: 'Invalid query params'},
            origin: "AggRetrievalPage",
            quoteID: quoteID || ''
           });
        }

      try {
        if ((Breakdown != null && Breakdown.trim() != '' ) || (Legal != null && Legal.trim() != '' ) || (CourtesyCar != null && CourtesyCar.trim() != '' )) {
            let paramNames = Object.keys(params);
            if(!paramNames.includes(Breakdown) && Breakdown != null) {
                let BreakdownOption = Breakdown;
                if(Breakdown.toLowerCase() === 'breakdown') {
                    BreakdownOption = 'Level2';
                }
                Object.assign(params, { Breakdown: BreakdownOption });
            }
            if(!paramNames.includes(Legal) && Legal != null) {
                Object.assign(params, { Legal: Legal });
            }
            if(!paramNames.includes(CourtesyCar) && CourtesyCar != null) {
                Object.assign(params, { CourtesyCar: CourtesyCar });
            }
        }
        setParams([...Params, params]);

        let aggCode = params.get('agg').toUpperCase();
        let aggLogoPath = AGG_LOGO_PATH[aggCode];
        const tempAggRetrievalDTO = {quoteID: quoteID, postCode: postCode, aggregator: aggCode, brand_itb: brand};
        setAggRetrievalDTO(tempAggRetrievalDTO);
        const index = aggLogoPath?.indexOf('/');
        aggLogoPath = `${aggLogoPath?.substring(0, index + 1)}quoteandbuy/${aggLogoPath?.substring(index + 1)}`;
        setAggLogoPath(aggLogoPath);
        setShowAggsMessage(true);

        updateIsAggsLoading(true);
        
      } catch (error) {
            history.push({ 
              pathname: '/error',
              data: error,
              origin: "AggRetrievalPage [Mapping selected coverages]",
              quoteID: quoteID || ''
             });
      };
    }, [CoverageUtil]);

    useEffect(() => {
      try {
        if (isAggsLoading) {
          LoadSaveService.retrieveAggsSubmission_AND(AggRetrievalDTO)
              .then((submissionData) => {
                  populateSubmission(submissionData, Params[0]);
              })
              .catch((error = {}) => {
                history.push({ 
                  pathname: '/error',
                  data: error,
                  origin: "AggRetrievalPage",
                  quoteID: AggRetrievalDTO.quoteID || ''
                });
              });
        }
        
      } catch (error) {
            history.push({ 
              pathname: '/error',
              data: error,
              origin: "AggRetrievalPage",
              quoteID: AggRetrievalDTO.quoteID || ''
             });
      };
    }, [isAggsLoading, Params]);

    const updateSubmission = (submission, submissionResponse) => {
        submission.quoteData.offeredQuotes = submissionResponse.quoteData.offeredQuotes;
        submission.quoteData = submissionResponse.quoteData;
        submission.lobData.personalMotor.offerings = submissionResponse.lobData.personalMotor.offerings;
        return submission;
    };

    const checkAndUpdateMissingDetails = (submission) => {
      //Handle bindData.autoRenew_itb not being set as bindData has been set at QuotePage
      if (submission.bindData.accountNumber && submission.bindData.autoRenew_itb.value === undefined) {
        submission.bindData.autoRenew_itb.value = true; 
      }

      //Handle invalid baseData.accountHolder.cellNumber from Aggs
      if (submission.baseData.accountHolder.cellNumber.value !== null && ! submission.baseData.accountHolder.cellNumber.aspects.valid) {
          submission.baseData.accountHolder.cellNumber.value = '07000000000';
      }
      //Handle invalid bindData.contactPhone from Aggs
      if ((submission.bindData.contactPhone.value !== null && ! submission.bindData.contactPhone.aspects.valid) || submission.bindData.contactPhone.value === undefined) {
          submission.bindData.contactPhone.value = '07000000000';
      }

      return submission;
  };

    const populateSubmission = useCallback((submissionData = {}, params = []) => {
      try {
          let submission = submissionData;
          const AggCoverages = CoverageUtil.mapAggsCoverages(params, brand);
          if(AggCoverages?.length > 0) {
              for (var cov in AggCoverages) {
                  if(AggCoverages[cov].SelectedTerm == null) {
                      submissionData = CoverageUtil.updateAggCoverageSelection(true, AggCoverages[cov].CoverageCode, submissionData, false);
                  }
                  else {
                      submissionData = CoverageUtil.updateAggCoverageTermSelection(AggCoverages[cov].SelectedTerm, AggCoverages[cov].CoverageCode, AggCoverages[cov].TermCode, submissionData, false);
                  }
              }
              CoverageUtil.updateQuote(submissionData, CustomQuoteService)
                  .then((submissionResponse) => {
                      submission = submissionResponse; 
                      const submissionVM = viewModelService.create(new Submission(submission), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO');
                      let submissionObj = checkAndUpdateMissingDetails(submissionVM);
                      history.push({ 
                        pathname: '/',
                        data: submissionObj.value
                      });
                  })
                  .catch((error = {}) => {
                    history.push({ 
                      pathname: '/error',
                      data: error,
                      origin: "AggRetrievalPage [Re-quoting selected coverages]",
                      quoteID: submissionData.quoteID || ''
                    });
                  });
              
          }
          else {
              const submissionVM = viewModelService.create(new Submission(submission), 'pc', 'edge.capabilities.quote.submission.dto.QuoteDataDTO');
              let submissionObj = checkAndUpdateMissingDetails(submissionVM);
              history.push({ 
                pathname: '/',
                data: submissionObj.value
              });
          }
      } catch (error) {
          history.push({ 
            pathname: '/error',
            data: error,
            origin: "AggRetrievalPage [populateSubmission]",
            quoteID: submissionData.quoteID || ''
          });
      };
    }, [viewModelService]);

    const overrideProps = {
        '@field': {
            showOptional: true,
            labelPosition: 'left',
            readOnly: true
        },
        AggMessageContainer: {
          visible: showAggsMessage
        },
        AggsLogoImage: {
          src: aggLogoPath,
          showLoader: false
        },
        AggsCarImage: {
          src: YarisCarImage,
          showLoader: false
        },
        AggsHeaderText: {
          content: htmlParser(translator(messagesTranslationsSet[`aggHeadingText${brand.toUpperCase()}`].defaultMessage))
        },
        inlineLoader: {
          loading: isAggsLoading,
          loadingMessage: 'Loading...',
          successMessage: ''
        }
    };
    const resolvers = {
        resolveClassNameMap: styles
    };

    const handleError = useCallback((error = {}) => {
        history.push({ 
                    pathname: '/error',
                    data: error,
                    origin: "AggRetrievalPage",
                    quoteID: AggRetrievalDTO.quoteID || ''
                   });
        return false;
    });
    
    return (
        <ErrorBoundary onError={handleError}>
              <ViewModelForm
                  uiProps={metadata.pageContent}
                  model={submissionVM}
                  overrideProps={overrideProps}
                  classNameMap={resolvers.resolveClassNameMap}
              />
        </ErrorBoundary>
    );

}
AggRetrievalPage.propTypes = wizardProps;
export default withRouter(withViewModelService(AggRetrievalPage));