import { defineMessages } from 'react-intl';

export default defineMessages({
    pmMainErrorBannerMessage: {
        id: 'quoteandbind.pm.views.pm-LP2of4',
        defaultMessage: "Please correct the errors on this page before continuing. We've found some errors on this page. The errors are shown in red.",
    },
    clickConfirmReadAboveInfoKFI: {
        id: 'quoteandbind.pm.views.pm-legal2-information-monthly.confirmInformation.kfi',
        defaultMessage: '<strong>Click to confirm that you have read the above information</strong>, and that you are aware that by proceeding with your application for credit, a search will be made of the records held about you by a credit reference agency. This search will leave a ‘footprint’ on your record with that agency, and may leave a record that could be seen by other lenders.'
    },
    clickConfirmReadAboveInfoTYA: {
        id: 'quoteandbind.pm.views.pm-legal2-information-monthly.confirmInformation.tya',
        defaultMessage: 'Click to confirm that you have read the above information and that you are aware that by proceeding with your application for credit, a search will be made of the records held about you by a credit reference agency. This search will leave a ‘footprint’ on your record with that agency, and may leave a record that could be seen by other lenders.'
    },
    pmCardDetailsStoredText: {
        id: 'quoteandbind.pm.views.pm-legal2-information-monthly.cardDetailsStored',
        defaultMessage: 'Your card details will be stored for any future payments or refunds if we’re unable to apply these to your credit agreement if this has been settled with Premium Credit Limited (for example, if you’ve made all your monthly payments).'
    },
    pmThePaymentReferenceTYA: {
        id: 'quoteandbind.pm.views.pm-legal2-monthly-information.The Payment reference.tya',
        defaultMessage: 'Please note - the payment reference shown on your bank statement will be PC/AIOI INSURANCE.',
    },
    pmThePaymentReferenceKFI: {
        id: 'quoteandbind.pm.views.pm-legal2-monthly-information.The Payment reference.kfi',
        defaultMessage: 'Please note - the payment reference shown on your bank statement will be <strong>PC/KWIKFITINS.</strong>',
    },
    pmBrandTagLineTYA: {
        id: 'quoteandbind.pm.views.pm-legal2-monthly-information.Tag line.tya',
        defaultMessage: 'Keeping your car covered year after year with Toyota Insurance Services',
    },
    pmBrandTagLineKFI: {
        id: 'quoteandbind.pm.views.pm-legal2-monthly-information.Tag line.kfi',
        defaultMessage: 'Keeping your car covered year after year with Kwik Fit Motor Insurance',
    },
    payByMonthlyContentTitleDescription3TYA: {
        id: "quoteandbind.pm.views.pm-legal2-information.payByMonthlyInstallmentsContentTitleDescription3.tya",
        defaultMessage: "The bank details you provide on the Direct Debit Instruction below will be passed to our third party finance provider, Premium Credit Limited. Premium Credit may begin collecting your Direct Debits before you sign your credit agreement to pay for any insurance cover you are receiving."
    },
    payByMonthlyContentTitleDescription3KFI: {
        id: "quoteandbind.pm.views.pm-legal2-information.payByMonthlyInstallmentsContentTitleDescription3.kfi",
        defaultMessage: "The bank details you provide on the Direct Debit instruction below will be passed to our third party finance provider, Premium Credit Limited. Premium Credit may begin collecting your Direct Debit before you sign your credit agreement to pay for any insurance cover you are receiving."
    },
    cardholderNotice: {
        id: 'quoteandbind.pm.views.pm-legal2-monthly-information.cardholder notice',
        defaultMessage: "<strong>Please note:</strong> If you are not the cardholder, it is your responsibility to ensure that you have the cardholder's authority to make this payment and for this card to be used for automatic renewal. You must also advise the cardholder of any changes to the premium.",
    },
});
